var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',{staticClass:"mt-4 text-white"},[_vm._v("Your comments")]),_c('b-form',{staticClass:"d-flex flex-column"},[_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('div',{class:{
              'form-group--error': _vm.$v.form.email.$error,
              'form-group--ok': !_vm.$v.form.email.$error && _vm.$v.form.email.$dirty,
            }},[_c('b-form-input',{staticClass:"form__input",class:{
                'mb-2': !_vm.$v.form.email.$error,
              },attrs:{"name":"email","type":"email","placeholder":"codemonkey@gmail.com"},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.email.$model"}}),(_vm.$v.form.email.$dirty)?_c('div',[(!_vm.$v.form.email.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input email ")]):_vm._e(),(!_vm.$v.form.email.email)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please enter a valid email ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('div',{class:{
              'form-group--error': _vm.$v.form.nickname.$error,
              'form-group--ok':
                !_vm.$v.form.nickname.$error && _vm.$v.form.nickname.$dirty,
            }},[_c('b-form-input',{staticClass:"form__input",class:{
                'mb-2': !_vm.$v.form.nickname.$error,
              },attrs:{"name":"name","type":"text","placeholder":"greatcoder12"},model:{value:(_vm.$v.form.nickname.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.nickname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.nickname.$model"}}),(_vm.$v.form.nickname.$dirty)?_c('div',[(!_vm.$v.form.nickname.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input a nickname ")]):_vm._e(),(
                  !_vm.$v.form.nickname.minLength || !_vm.$v.form.nickname.maxLength
                )?_c('div',{staticClass:"error mb-2"},[_vm._v(" Your nickname must be 4-20 characters long ")]):_vm._e()]):_vm._e()],1)])],1),_c('div',{class:{
          'form-group--error': _vm.$v.form.text.$error,
          'form-group--ok': !_vm.$v.form.text.$error && _vm.$v.form.text.$dirty,
        }},[(!_vm.previewMarkdown)?_c('div',[_c('b-form-textarea',{staticClass:"form__input",class:{
              'mb-2': !_vm.$v.form.text.$error,
            },attrs:{"rows":"3","name":"text","placeholder":"Leave your answer here... P.S You can use markdown"},model:{value:(_vm.$v.form.text.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.text, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.text.$model"}}),(_vm.$v.form.text.$dirty)?_c('div',[(!_vm.$v.form.text.required)?_c('div',{staticClass:"error mb-2"},[_vm._v(" Please input your answer ")]):_vm._e()]):_vm._e()],1):_c('div',_vm._l((_vm.categorizeCodeSnippetsAndText(
              _vm.form.text
            )),function(description,idx){return _c('div',{key:`questionnaire-description-${idx}`},[(description.type === 'code')?_c('span',[_c('CodeEditor',{staticClass:"d-block",attrs:{"languageId":_vm.langToEditor[description.lang] || 'javascript',"canEdit":false,"editorHeight":"100px"},model:{value:(description.phrase),callback:function ($$v) {_vm.$set(description, "phrase", $$v)},expression:"description.phrase"}})],1):_c('span',{domProps:{"innerHTML":_vm._s(_vm.marked(description.phrase))}})])}),0)])],1),_c('div',{staticClass:"d-flex my-2 justify-content-between text-primary"},[_c('b-button',{staticClass:"px-1 py-1 mb-1 text-capitalize",attrs:{"variant":"secondary"},on:{"click":function($event){_vm.previewMarkdown = !_vm.previewMarkdown}}},[_vm._v(" "+_vm._s(_vm.previewMarkdown ? "Continue Editing" : "Preview")+" ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" POST "),(_vm.submitting)?_c('b-spinner',{attrs:{"variant":"primary","small":""}}):_vm._e(),(!_vm.submitting)?_c('b-icon-arrow-right-circle-fill'):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }