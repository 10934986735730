import { render, staticRenderFns } from "./SingleChallenge.vue?vue&type=template&id=acf8709a&scoped=true&"
import script from "./SingleChallenge.vue?vue&type=script&lang=js&"
export * from "./SingleChallenge.vue?vue&type=script&lang=js&"
import style0 from "./SingleChallenge.vue?vue&type=style&index=0&id=acf8709a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf8709a",
  null
  
)

export default component.exports